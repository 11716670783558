<template>
  <section class="background--views">
    <v-container>
      <component
        :is="component"
      ></component>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "InternetMovil",
  components: {
    InternetMovilTable: () =>
      import("../components/internet-movil/InternetMovilTable"),
  },
  data: () => ({
    component: "InternetMovilTable",

  }),
};
</script>

<style lang="scss" scoped>
</style>